import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AiCoder from './AiCoder';
import reportWebVitals from './reportWebVitals';
import CONFIG from './config';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AiCoder backendChatURL={CONFIG.BACKEND_CHAT_URL} backendCodeGeneratorURL={CONFIG.BACKEND_CODE_GERATOR_URL} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
