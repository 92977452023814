import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './AiCoder.css';

const AiCoder = ({backendChatURL,backendCodeGeneratorURL}) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingCode, setIsGeneratingCode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const chatEndRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto'; // Reset height to auto
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`; // Set height based on scrollHeight
    }
  }, [input]); // Trigger when the input value changes

  const handleSendMessage = async () => {
    if (!input) return;

    const userMessage = { text: input, sender: 'user', timestamp: new Date().toLocaleTimeString() };
    const updatedMessages = [...messages, userMessage];

    // Add a temporary bot message with a loader
    const loaderMessage = { text: '', sender: 'bot', isLoading: true };
    setMessages([...updatedMessages, loaderMessage]);
    setInput('');

    try {
      const response = await axios.post(backendChatURL, {
        message: input,
        previousMessages: updatedMessages,
      });

      const botMessageText = response.data.message;
      const botMessage = { 
        text: botMessageText, 
        sender: 'bot', 
        timestamp: new Date().toLocaleTimeString(),
        isSQL: extractSQL(botMessageText) !== null // Check if the response contains SQL
      };
      // Replace the loader message with the actual bot message
      setMessages([...updatedMessages, botMessage]);
    } catch (error) {
      console.error('Error:', error);
      const botMessage = { text: 'Error generating response', sender: 'bot', timestamp: new Date().toLocaleTimeString() };
      // Replace the loader message with an error message
      setMessages([...updatedMessages, botMessage]);
    }
  };

  const extractSQL = (text) => {
    // Regular expression to capture SQL code within triple backticks and preceded by `sql`
    let createTableRegex = /CREATE TABLE[\s\S]*?;/g;
    const match = text.match(createTableRegex);
    let r = (match ? match.join('') : null); // Return the captured SQL code, trimmed
    return r;
  };

  const handleGenerateCode = async (index) => {
    const lastMessage = messages[index];
    if (!lastMessage || lastMessage.sender !== 'bot') {
      openModal('No SQL found in the last bot response.');
      return;
    }

    const sqlCode = extractSQL(lastMessage.text);
    if (!sqlCode) {
      openModal('The last bot response does not contain SQL.');
      return;
    }

    setIsGeneratingCode(true);
    try {
      const formData = new FormData();
      formData.append('data[modules][sql]', sqlCode);
      await axios.post(backendCodeGeneratorURL, formData,{ headers: {'Content-Type': 'multipart/form-data'}});
      openModal('Database updated successfully.');
    } catch (error) {
      console.error('Error executing SQL:', error);
      openModal('Failed to update the database.');
    } finally {
      setIsGeneratingCode(false);
    }
  };

  const openModal = (message) => {
    setModalMessage(message);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="chat-wrapper">
      <div className="chat-container">
        <div className="messages">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message-row ${message.sender === 'user' ? 'user-row' : 'bot-row'}`}
            >
              {message.sender === 'bot' && (
                <img src="/bot-icon.png" alt="Bot" className="avatar bot-avatar" />
              )}
              <div className={`message-bubble ${message.sender}`}>
                {message.isLoading ? ( // Check if it's a loading message
                  <div className="loading-indicator"></div> // Show loading indicator
                ) : true ? ( // Check if the message is SQL 
                  <pre className="sql-code">{message.text}</pre> // Use <pre> tag for SQL
                ) : (
                  <div className="message-text">{message.text}</div>
                )}
                <div className="message-timestamp">{message.timestamp}</div>
                {message.isSQL?<button className="code-button" onClick={(e)=>{handleGenerateCode(index)}} disabled={isGeneratingCode}><img src="code-icon.png" alt="Generate Code" className="code-icon" /></button>:''}
              </div>
              {message.sender === 'user' && (
                <img src="/user-icon.png" alt="User" className="avatar user-avatar" />
              )}
            </div>
          ))}
          <div ref={chatEndRef} />
        </div>

        {isGeneratingCode && <div className="loading-spinner sql-loader">Updating database...</div>}

        <div className="input-container">
          <textarea
            ref={inputRef}
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type your message..."
          />
          <button className="send-button" onClick={handleSendMessage} disabled={isLoading}>
            <img src="send-icon.png" alt="Send" className="send-icon" />
          </button>
          
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <p>{modalMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AiCoder;
